<template>
    <v-dialog
            v-model="dialog"
            max-width="450"
            persistent
        >
            <v-form
                ref="suspendeForm"
                onSubmit="return false;"
                v-model="validaObservacion"
            >
                <v-card>
                    <v-card-title
                        class="headline orange lighten-2"
                        primary-title
                    >
                        Agregar observación
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-text-field
                            v-model="contenido"
                            :rules="[value => !!value || 'Requerido.']"
                            label="Observaciones"
                            rounded
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="success"
                            :disabled="!validaObservacion"
                            @click="agrega(item)"
                        >
                            Agregar observación
                        </v-btn>

                        <v-btn color="error" @click="cancelaObservacion">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
</template>

<script>
export default {
    props:{
        item: Object,
        dialogObservaciones: Boolean,
        cargaTransfusionesTodas: Function
    },
    data: () => ({
        dialogObservacion: false,
        contenido: "",
        validaObservacion: false
    }),
    methods:{
        async agrega( item ){
            try {
                await this.$http({
                    method:'POST',
                    url:'/enfermeria/agregarObservacionTransfusion',
                    data:{
                        id: item.id,
                        contenido: this.contenido
                    }
                })
                this.contenido = ""
                this.dialog = false
                this.cargaTransfusionesTodas()
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingSol = false
            }
        },
        cancelaObservacion(){
            this.dialog=false
            this.contenido=""
        }
    },
    computed:{
        dialog:{
            get(){
                return this.dialogObservaciones
            },
            set(value){
                this.$emit('update:dialogObservaciones',value)
            },
        },
    },
}
</script>

<style>

</style>